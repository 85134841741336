import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import slug from 'slug';
import _ from 'lodash';
import Layout from '../components/layout';

const Services = (props) => {
  const { data: { allServicesJson: { edges } } } = props;
  return (
    <Layout>
      <section className="breadcrumb-area">
        <div className="container text-center">
          <h1>Services</h1>
        </div>
      </section>
      {/* End breadcrumb area */}
      {/* Start breadcrumb bottom area */}
      <section className="breadcrumb-botton-area">
        <div className="container">
          <div className="left pull-left">
            <ul>
              <li><a href="index.html">Home</a></li>
              <li><i className="fa fa-caret-right" aria-hidden="true" /></li>
              <li>Services</li>
            </ul>
          </div>
          <div className="right pull-right">
            {/* <a href="#">
              <i className="fa fa-share-alt" aria-hidden="true" />
Share
            </a> */}
          </div>
        </div>
      </section>
      {/* End breadcrumb bottom area */}
      {/* Start services area */}
      <section className="call-to-action-area services-area">
        <div className="container">
          <div className="row">
            {/* Start single item */}
            {
              edges.map(service => (
                <div className="col-md-4">
                  <div className="single-item text-center">
                    <Link to={`/services/${slug(_.lowerCase(service.node.name))}`}>
                      <h5>{service.node.name}</h5>
                    </Link>
                    <div className="img-holder">
                      <Img
                        src={service.node.image.childImageSharp.sizes.src}
                        sizes={service.node.image.childImageSharp.sizes}
                        alt={service.node.name}
                      />
                      <div className="overlay">
                        <div className="box">
                          <div className="content">
                            <Link to={`/services/${slug(_.lowerCase(service.node.name))}`}><i className="fa fa-link" aria-hidden="true" /></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-holder">
                      <p dangerouslySetInnerHTML={{ __html: service.node.shortDescription }} />
                    </div>
                  </div>
                </div>

              ))
            }
            {/* End single item */}
          </div>
        </div>
      </section>
    </Layout>
  );
};

Services.propTypes = {
  data: PropTypes.shape(PropTypes.object),
};

Services.defaultProps = {
  data: {},
};
export default Services;

export const pageQuery = graphql`
query servicesPage{
  allServicesJson{
    edges{
      node{
        name
        shortDescription
        image{
          childImageSharp{
            id
            sizes( maxWidth: 800, maxHeight: 500 ) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }  
}
`;
